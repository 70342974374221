* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  box-sizing: border-box;

  background: transparent;

  font-family: inherit;

  text-decoration: none;
}

ol,
ul,
li {
  list-style: none;
  display: block;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a img {
  border: none;
}

button,
a {
  cursor: pointer;
  color: inherit;
}

textarea {
  resize: none;
}

button,
select {
  appearance: none !important;
  -webkit-appearance: none !important;
  border-radius: 0;
}


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.App {
  width: 100vw;
  min-height: 100vh;
  background-color: lightgray;
}


.header-logo {
  height: 50px;
}

.footer-logo {
  height: 40px;
  margin-right: 15px;
}